import barba, { IViewData } from '@barba/core';
import { lazyload } from '../inits/lazyload';
import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import adultPopup from '../inits/adult-popup';
import reveal from '../inits/reveal';
import splitLines from '../inits/split-lines';
import splitChars from '../inits/split-chars';
import wrapPhotos from '../inits/wrap-photos';
import selects from '../inits/selects';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap';

let enterTl = gsap.timeline({ paused: true, delay: 0.8 });
export function initBaseView() {
    barba.hooks.enter((data) => {
        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        enterTl.restart();
    });

    barba.hooks.beforeEnter((data) => {
        if (data) {
            document.body.classList.add(data.next.namespace);
        }
        adultPopup.init();
        reveal.init(data?.next.container);
        selects.init(data?.next.container);
        splitLines(data?.next.container);
        splitChars(data?.next.container);
        wrapPhotos(data?.next.container);
        enterTl
            .from('.a-header-burger', { opacity: 0 }, 0.9)
            .from('.a-header-logo', { y: -20, opacity: 0 }, 0.8)
            .from('.a-header-social', { opacity: 0, stagger: { each: 0.1 } }, 1);
    });

    barba.hooks.beforeLeave((data) => {
        reveal.destroy(data?.current.container);
        selects.destroy(data?.current.container);
        const scrollTriggers = ScrollTrigger.getAll();

        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                if (!st.trigger?.classList.contains('js-anchor-target')) {
                    st.kill();
                }
            });
        }
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.afterLeave((data) => {
        if (data) {
            document.body.classList.remove(data.current.namespace);
        }
    });
}
