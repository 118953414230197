import FormSender from '@chipsadesign/form-sender';
import createValidator from '../../modules/validator';

type CustomError = {
    message: string;
    code: string | number;
};

type CustomResponseData = {
    message: string;
};

type CustomResponse = {
    success: boolean;
    message: string;
    data?: CustomResponseData | null;
    errors?: CustomError[];
};

const map = new WeakMap<
    HTMLFormElement,
    {
        formSender: Record<string, any>;
        submitFn: (event: Event) => void;
    }
>();

function sendTarget(strTarget: string) {
    if (strTarget !== undefined && ym) {
        // ym(87118866, 'reachGoal', strTarget);
    }
}

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = false;
    }

    if (failureBlock) {
        failureBlock.hidden = true;
    }

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = true;
    }

    if (failureBlock) {
        failureBlock.hidden = false;
    }

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    messagesContainer?.classList.remove('form-messages--hidden');
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    messagesContainer?.classList.add('form-messages--hidden');

    setTimeout(() => {
        if (successBlock) {
            successBlock.hidden = true;
        }

        if (failureBlock) {
            failureBlock.hidden = true;
        }
    }, 300);
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

function init(container: HTMLElement | Document = document) {
    const form = container.querySelector<HTMLFormElement>('.js-form');
    if (form) {
        let isSubmitting = false;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'end',
            },
        });
        const formSender = new FormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: (response: CustomResponse) => {
                if (response.success) {
                    showSuccessMessage(form, response.message || 'С вами свяжутся ближайшее время ');
                    const formTarget = form.getAttribute('data-target');
                    if (formTarget) {
                        sendTarget(formTarget);
                    }
                } else {
                    showFailureMessage(form, response.message || 'Произошла ошибка');
                }
            },
            onError: (response: CustomResponse) => {
                if (response.errors && response.errors.length > 0) {
                    let str = '';
                    response.errors.forEach((error: CustomError) => {
                        str += `${error.message}; `;
                    });
                    showFailureMessage(form, str);
                }
            },
            onComplete: () => {
                showFormMessages(form);
                setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(e: Event) {
            if (isSubmitting) return;
            e.preventDefault();

            let timer: NodeJS.Timeout;
            const isFormValid = validator.validate();

            if (isFormValid) {
                isSubmitting = true;

                timer = setTimeout(() => {
                    form?.classList.add('is-load');
                }, 300);
                form?.classList.add('is-overlay');

                formSender.send().finally(() => {
                    isSubmitting = false;
                    clearTimeout(timer);
                    form?.classList.remove('is-load');
                    form?.classList.remove('is-overlay');
                });
            }
        }

        const formMessageElements = Array.from(form.querySelectorAll<HTMLElement>('.js-form-message'));

        formMessageElements.forEach((el) =>
            el.addEventListener('click', () => {
                hideFormMessages(form);
            }),
        );

        form.addEventListener('submit', submitFn);

        map.set(form, { formSender, submitFn });
    }
}

function destroy(container: HTMLElement | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>('.js-form'));

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            form.removeEventListener('submit', data.submitFn);
        }
    });
}

const _module = { init, destroy };

export default _module;
