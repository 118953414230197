import SlimSelect from 'slim-select';

const map = new Map();

function init(container: HTMLElement | Document = document) {
    const selects = Array.from(container.querySelectorAll('.js-select'));
    selects.forEach((element) => {
        const instance = new SlimSelect({
            select: element,
            showSearch: false,
            showContent: window.matchMedia('(max-width: 767px)').matches ? 'up' : 'down',
        });
        map.set(element, instance);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const selects = Array.from(container.querySelectorAll('.js-select'));
    selects.forEach((element) => {
        const instance = map.get(element);
        instance?.destroy();
        map.delete(element);
    });
}

const _module = { init, destroy };

export default _module;
