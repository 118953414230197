import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { SplitText } from 'gsap/SplitText';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { preloader } from './components/preloader';
import barba from '@barba/core';
import { initBaseView } from './views/_base';
import BaseTransition from './transitions/base';
import lazyView from './views/_lazy';
import menuPopup from './inits/menu-popup';
import inputMasks from './inits/masks';
import ajaxForms from './inits/ajax-forms';
import anchors from './inits/anchors';
import type { ScrollInstance } from './components/scroll';
import { timeout } from './utils/timeout';

document.documentElement.classList.add('js-ready');

type ScrollInstanceObject = {
    instance: ScrollInstance | null | undefined;
};

export const scrollInstance: ScrollInstanceObject = {
    instance: null,
};

applyPolyfills().then(async () => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger, TextPlugin, ScrollToPlugin);
    menuPopup.init();
    ajaxForms.init();

    const menuPopupElement = document.querySelector<HTMLElement>('[data-lit-popup="menu"]');
    if (menuPopupElement) {
        anchors.init(menuPopupElement);
    }
    const formPopupElement = document.querySelector<HTMLElement>('[data-lit-popup="feedback"]');
    if (formPopupElement) {
        inputMasks.init(formPopupElement);
    }

    /**
     * Views
     */
    initBaseView();

    const main = document.querySelector('.js-main');
    const header = document.querySelector('.js-header');

    main?.classList.add('is-hidden');
    const tl = gsap.timeline({ defaults: { duration: 0.6 }, paused: true });
    tl.from(header, { xPercent: -100 });
    // Initial load
    preloader
        .loadAssets()
        .then(() => timeout(1000))
        .then(() => {
            preloader.leave();
            tl.play();

            barba.init({
                transitions: [BaseTransition],
                views: [
                    lazyView('index-page', () =>
                        import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('catalog-page', () =>
                        import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('detail-page', () =>
                        import(/* webpackChunkName: "detail-page", webpackPrefetch: true */ './views/detail').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('error-page', () =>
                        import(/* webpackChunkName: "error-page", webpackPrefetch: true */ './views/error').then(
                            (m) => m.default,
                        ),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                // prevent: ({ el }) => el.classList.contains('chps-content-list-pagination-link'),
                timeout: 5000,
                debug: NODE_ENV === 'development',
                requestError: (trigger, action, url, response) => {
                    // go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
            });
        });
    /**
     * End of views
     */

    // Always at the end
    // module.hot?.accept();
});
