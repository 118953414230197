import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const curtain = document.querySelector('.js-page-curtain');
const fragment = document.createDocumentFragment();
let curtainChild: HTMLElement;
const duration = 0.8;
const ease = 'power3.out';

if (curtain) {
    for (let i = 0; i < 1; i++) {
        const el = document.createElement('div');
        fragment.appendChild(el);
        curtainChild = el;
    }

    curtain.appendChild(fragment);
}

export default {
    name: 'default page transition',

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    leave() {
        return new Promise((resolve) => {
            curtain?.classList.add('is-active');
            gsap.timeline()
                .set(curtainChild, {
                    x: '-100%',
                })
                .to(curtainChild, {
                    duration,
                    x: '0%',
                    ease,
                    onComplete: resolve,
                });
        });
    },

    after() {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.refresh();
            });
        }

        requestAnimationFrame(() => {
            gsap.timeline().to(curtainChild, {
                duration,
                x: '100%',
                ease,
                onComplete: () => {
                    curtain?.classList.remove('is-active');
                },
            });
        });
    },
};
