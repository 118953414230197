import Cookie from 'js-cookie';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { scrollInstance } from '../app';

async function init() {
    const adultCheckEl = document.querySelector<LitPopupElement>('.js-adult-check-container');
    const adultCheckbox = document.querySelector<HTMLInputElement>('.js-adult-checkbox');
    const adultCheckYesBtn = document.querySelector<HTMLElement>('.js-adult--yes');

    const isMobile = window.matchMedia('(max-width: 1279px)').matches;

    function removeContainer() {
        adultCheckEl?.remove();
    }

    if (Cookie.get('IS_ADULT') === 'true') {
        document.body.classList.remove('no-scroll');
        if (scrollInstance.instance) {
            scrollInstance.instance.resumeRendering();
        }
        removeContainer();
    } else {
        if (!isMobile) {
            ScrollTrigger.normalizeScroll(false);
        }
        document.body.classList.add('no-scroll');
        if (scrollInstance.instance) {
            scrollInstance.instance.pauseRendering();
        }
        window.scrollTo({ top: 0, behavior: 'auto' });

        adultCheckbox?.addEventListener('change', () => {
            adultCheckbox.checked ? adultCheckbox.classList.remove('is-error') : '';
        });

        adultCheckYesBtn?.addEventListener('click', () => {
            if (adultCheckbox && !adultCheckbox.checked) {
                adultCheckbox.classList.add('is-error');
            } else {
                Cookie.set('IS_ADULT', 'true', { expires: 365 });
                document.body.classList.remove('no-scroll');
                if (scrollInstance.instance) {
                    scrollInstance.instance.resumeRendering();
                }
                if (!isMobile) {
                    ScrollTrigger.normalizeScroll(true);
                }
                adultCheckEl?.close();
                setTimeout(removeContainer, 300);
            }
        });
    }
}

const _module = { init };

export default _module;
