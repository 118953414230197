const menu = document.querySelector('[data-lit-popup="menu"]');
const menuTrigger = document.querySelector<HTMLElement>('.js-menu-trigger');

function onOpen() {
    setTimeout(() => {
        if (menuTrigger) {
            menuTrigger.classList.add('is-open');
            menuTrigger.removeAttribute('data-lit-popup-open');
            menuTrigger.setAttribute('data-lit-popup-close', 'menu');
        }
    }, 10);
}

function onClose() {
    setTimeout(() => {
        if (menuTrigger) {
            menuTrigger.classList.remove('is-open');
            menuTrigger.removeAttribute('data-lit-popup-close');
            menuTrigger.setAttribute('data-lit-popup-open', 'menu');
        }
    }, 10);
}

function init() {
    menu?.addEventListener('open', onOpen);
    menu?.addEventListener('close', onClose);
}

export default { init };