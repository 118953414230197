import headerSizes from '../utils/headerSizes';
import type { ScrollInstance } from '../components/scroll';
import { scrollInstance } from '../app';
import gsap from 'gsap';

let scroll: ScrollInstance | null | undefined;

function scrollToElement(element: HTMLElement | undefined | null, scroll: ScrollInstance | null | undefined) {
    if (element) {
        if (scroll) {
            let parent = element.parentElement;
            let offsetLeft = element.offsetLeft;
            while (parent && parent.dataset.scroll !== 'container') {
                offsetLeft += parent.offsetLeft;
                parent = parent.parentElement;
            }
            scroll.setDocScrollValue(offsetLeft);
        } else {
            gsap.to(window, {
                scrollTo: {
                    y: element,
                    offsetY: headerSizes.height,
                },
                duration: 1,
            });
        }
    }
}

function onClick(e: Event) {
    e.preventDefault();
    const targetElement = e.target as HTMLElement;
    const linkElement = targetElement.closest<HTMLElement>('[data-scroll-to]');
    if (linkElement) {
        const popupWrapper = linkElement.closest('app-lit-popup');
        popupWrapper?.close();
        if (linkElement.dataset && linkElement.dataset.scrollTo) {
            const element = document.getElementById(linkElement.dataset.scrollTo);
            if (element) {
                scrollToElement(element, scroll);
            }
        }
    }
}

let timer: NodeJS.Timeout;

function init(container: HTMLElement | Document = document) {
    scroll = scrollInstance.instance;
    clearTimeout(timer);
    const anchorLinks = Array.from(container.querySelectorAll('[data-scroll-to]'));
    anchorLinks.forEach((link) => link.addEventListener('click', onClick));
    if (window.location.hash) {
        timer = setTimeout(() => {
            const element = container.querySelector<HTMLElement>(window.location.hash);
            scrollToElement(element, scroll);
        }, 400);
    }
}
function destroy(container: HTMLElement | Document = document) {
    const anchorLinks = Array.from(container.querySelectorAll('[data-scroll-to]'));
    anchorLinks.forEach((link) => link.addEventListener('click', onClick));
    clearTimeout(timer);
    scroll = null;
}

const _module = { init, destroy };

export default _module;
