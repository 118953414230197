import './YandexMap.scss';
import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import loadScript from '../../../modules/load-script';
import gsap from 'gsap';
import headerSizes from '../../utils/headerSizes';

export type Placemark = {
    id: number;
    coords: [number, number];
    name: string;
    cityId?: string | number;
    address?: string;
    phone?: string;
    phoneUrl?: string;
};

type City = {
    id: number;
    city: string;
    coords: [number, number];
};

export interface YandexMap {
    apiKey: string;
    centerLat: number;
    centerLng: number;
    instance: any;
    marker: string;
    zoom: number;
    isCardVisible: boolean;
    isMapsInited: boolean;
    currentCoords: [number, number];
    marks: any[];
    // clusterer: any;ssssssss
    activeMark: any;
    currentCity?: number;
    data?: {
        placemarks: Placemark[];
    };
    citiesData?: {
        cities: City[];
    };
    _animatedLayout: any;
    _isFetching: boolean;
    _error: Error | null;
    _observer: IntersectionObserver;
}

let isAPILoaded = true;

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);

        this.instance = null;
        this.zoom = 15;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = this.getAttribute('map-data') ? JSON.parse(this.getAttribute('map-data')!) : null;
        this.citiesData = this.getAttribute('cities-data') ? JSON.parse(this.getAttribute('cities-data')!) : null;
        this.currentCity = this.getAttribute('current-city') ? JSON.parse(this.getAttribute('current-city')!) : null;
        this.marks = [];
        // this.clusterer;
        this.isCardVisible = false;
        this.isMapsInited = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
            instance: {
                type: Object,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _onChangeCity(e: Event) {
        const targetSelect = e.target as HTMLSelectElement;
        const cityId = targetSelect.value;
        const retailPopupEl = document.querySelector<HTMLElement>('[data-lit-popup="retail"]');
        if (this.citiesData && this.citiesData.cities) {
            const currentCity = Array.from(this.citiesData.cities).find((city) => {
                return city.id.toString() === cityId.toString();
            });
            if (retailPopupEl && currentCity) {
                retailPopupEl.dataset.current = currentCity.id.toString();
            }
            if (this.instance && currentCity) {
                const coordsArray: [number, number][] = [];
                this.data?.placemarks.forEach((mark) => {
                    if (mark.cityId && mark.cityId.toString() === cityId.toString()) {
                        coordsArray.push(mark.coords);
                    }
                });
                const filteredCoords = ymaps.util.bounds.fromPoints(coordsArray);
                this.instance.setBounds(filteredCoords, {
                    checkZoomRange: true,
                    zoomMargin: 100,
                });
            }
        }
    }

    _loadAPI(): Promise<void> {
        return new Promise((resolve) => {
            if (isAPILoaded) {
                resolve();
            } else {
                loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`).then(() => {
                    isAPILoaded = true;
                    resolve();
                });
            }
        });
    }

    _init() {
        this._error = null;
        this._isFetching = true;
        const citySelectEl = document.querySelector('.js-retail-cities-select');
        if (citySelectEl) {
            citySelectEl.addEventListener('change', (e) => {
                this._onChangeCity(e);
            });
        }
        const { ymaps } = window;
        ymaps.ready(() => {
            const _this = this;
            let mapBounds: [number, number][] | null = null;
            if (this.currentCity && this.data) {
                const coordsArray: [number, number][] = [];
                this.data.placemarks.forEach((placemark) => {
                    if (placemark.cityId?.toString() === this.currentCity?.toString()) {
                        coordsArray.push(placemark.coords);
                    }
                });
                mapBounds = ymaps.util.bounds.fromPoints(coordsArray);
            }
            this.instance = new ymaps.Map(this.renderRoot.querySelector('.map'), {
                center: [this.centerLat, this.centerLng],
                zoom: this.zoom,
                controls: ['zoomControl'],
            });

            this.instance.setBounds(mapBounds, {
                checkZoomRange: true,
                zoomMargin: 100,
            });

            const elements: any[] = [];

            const animatedLayout = ymaps.templateLayoutFactory.createClass(
                `<button class="map-placemark">
                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.714844" y="29" width="40" height="40" transform="rotate(-45 0.714844 29)" fill="#E62D36"/>
                            <rect x="17.6855" y="29" width="16" height="16" transform="rotate(-45 17.6855 29)" fill="#F4E3C1"/>
                        </svg>
                    </button>`,
                {
                    build() {
                        animatedLayout.superclass.build.call(this);
                        const element = this.getParentElement().getElementsByClassName('map-placemark')[0];
                        elements.push(element);
                        // Если метка выбрана, то увеличим её размер.
                        const size = 40;
                        // Зададим фигуру активной области.
                        this.getData().options.set('shape', {
                            type: 'Circle',
                            coordinates: [size / 2, size / 2],
                            radius: size / 2,
                        });
                        // Если метка выбрана, то зададим класс и запустим анимацию.
                        if (this.isActive) {
                            element.classList.add('is-active');
                        } else if (this.inited) {
                            element.classList.remove('is-active');
                        }

                        const closeAll = () => {
                            document.body.classList.remove('no-scroll');
                            _this.isCardVisible = false;
                            if (_this.activeMark && _this.activeMark !== this) {
                                _this.activeMark.isActive = false;
                            }
                            _this.renderRoot.querySelector('.card.is-active')?.classList.remove('is-active');
                            elements.forEach((el) => {
                                el.classList.remove('is-active');
                            });
                        };

                        _this.addEventListener('overlay-click', closeAll);
                        if (!this.inited) {
                            this.inited = true;
                            this.isActive = false;
                            // При клике по метке будем перестраивать макет.
                            this.getData().geoObject.events.add(
                                'click',
                                function (this: any, event: any) {
                                    closeAll();

                                    this.isActive = !this.isActive;
                                    _this.isCardVisible = this.isActive;
                                    const mark = event.get('target');
                                    const index = _this.marks.indexOf(mark);

                                    if (this.isActive) {
                                        element.classList.add('is-active');

                                        if (index > -1) {
                                            if (matchMedia('(max-width: 1279px)').matches) {
                                                document.body.classList.add('no-scroll');
                                                gsap.to(window, {
                                                    scrollTo: {
                                                        y: _this.renderRoot.querySelector('.map'),
                                                        offsetY: headerSizes.height,
                                                    },
                                                    duration: 1,
                                                });
                                            }
                                            const currentCard =
                                                _this.renderRoot.querySelectorAll<HTMLElement>('.card')[index];
                                            if (currentCard) {
                                                currentCard.classList.add('is-active');
                                                const xClick = event.originalEvent.domEvent.originalEvent.offsetX;
                                                const yClick = event.originalEvent.domEvent.originalEvent.offsetY;
                                                const cardWidth = currentCard.offsetWidth;
                                                const cardHeight = currentCard.offsetHeight;
                                                let left = xClick;
                                                let top = yClick;
                                                if (xClick < cardWidth / 2) {
                                                    left = cardWidth / 2;
                                                } else if (xClick > innerWidth - cardWidth) {
                                                    left = innerWidth - cardWidth;
                                                }
                                                if (yClick < cardHeight / 2) {
                                                    top = cardHeight / 2;
                                                } else if (yClick > innerHeight - cardHeight / 2) {
                                                    top = innerHeight - cardHeight / 2;
                                                }
                                                currentCard.style.top = `${top}px`;
                                                currentCard.style.left = `${left}px`;
                                            }
                                        }
                                    }

                                    _this.activeMark = this;
                                },
                                this,
                            );
                            this.getData().geoObject.events.add(
                                'mouseenter',
                                function (this: any) {
                                    element.classList.add('is-hovered');
                                },
                                this,
                            );
                            this.getData().geoObject.events.add(
                                'mouseleave',
                                function (this: any) {
                                    element.classList.remove('is-hovered');
                                },
                                this,
                            );
                        }
                    },
                },
            );
            this._animatedLayout = animatedLayout;

            if (this.data) {
                if (this.data.placemarks?.[0]?.coords) {
                    this.currentCoords = this.data.placemarks[0].coords;
                }

                this.marks = [];

                this.data.placemarks.forEach((placemark) => {
                    this._addMarker(...placemark.coords);
                });

                // this.clusterer = new ymaps.Clusterer({
                //     clusterIconLayout: ymaps.templateLayoutFactory.createClass(
                //         `<div class="cluster-icon">
                //             <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                 <rect x="0.714844" y="29" width="40" height="40" transform="rotate(-45 0.714844 29)" fill="#E62D36"/>
                //                 <rect x="17.6855" y="29" width="16" height="16" transform="rotate(-45 17.6855 29)" fill="#F4E3C1"/>
                //             </svg>
                //             <div class="cluster-icon__text">
                //                 {{ properties.geoObjects.length }}
                //             </div>
                //         </div>`,
                //     ),
                //     // Чтобы метка была кликабельной, переопределим ее активную область.
                //     clusterIconShape: {
                //         type: 'Rectangle',
                //         coordinates: [
                //             [0, 0],
                //             [56, 56],
                //         ],
                //     },
                //     hasBalloon: false,
                //     // gridSize: 256
                // });

                // this.clusterer.add(this.marks);
                // this.instance.geoObjects.add(this.clusterer);
            }

            this.instance.behaviors.disable('scrollZoom');

            if (matchMedia('(pointer: coarse)').matches) {
                this.instance.behaviors.disable('drag');
            }

            this.instance.events.add('click', this._closeCard);
            window.addEventListener('resize', this._onResize);
            this.isMapsInited = true;
        });
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.geoObjects.removeAll();
            this.instance.destroy();
            this.instance = null;
        }
    }

    protected _onResize() {
        this.instance?.container.fitToViewport();
    }

    setActiveMarkById(id: number) {
        const placemark = this.data?.placemarks.find((placemark) => placemark.id === id);

        if (placemark) {
            const index = this.data?.placemarks.indexOf(placemark);

            if (typeof index === 'number') {
                const mark = this.marks[index];
                mark?.events.fire('click', {
                    coordPosition: mark.geometry.getCoordinates(),
                    target: mark,
                });
            }
        }
    }

    protected _addMarker(lat: number, lng: number) {
        const markOptions = {
            iconLayout: this._animatedLayout,
            hasBalloon: false,
        };
        const mark = new ymaps.Placemark([lat, lng], {}, markOptions);
        this.marks.push(mark);
        this.instance.geoObjects.add(mark);
    }

    protected _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        this.dispatchEvent(new Event('overlay-click', { composed: true }));
    }

    render() {
        return html` <div class="map-container js-anchor-target" id="points">
            <div class="map">${this._renderMap()}</div>
            <div
                class="${classMap({ 'card-overlay': true, 'card-overlay--visible': this.isCardVisible })}"
                @click="${this._closeCard}"
            ></div>
            ${this.instance
                ? this.data?.placemarks.map(
                      (data) => html`
                          <div class="card map-card">
                              <button class="card-close-btn" aria-label="Закрыть" @click="${this._closeCard}">
                                  <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <line
                                          x1="0.707107"
                                          y1="1.29289"
                                          x2="20.7071"
                                          y2="21.2929"
                                          stroke="black"
                                          stroke-width="2"
                                      />
                                      <line
                                          x1="0.736008"
                                          y1="21.2929"
                                          x2="20.736"
                                          y2="1.29289"
                                          stroke="black"
                                          stroke-width="2"
                                      />
                                  </svg>
                              </button>
                              <div class="card__top">
                                  ${data.name ? html`<h4 class="h4 card__name">${data.name}</h4>` : ''}
                              </div>
                              ${data.address
                                  ? html`
                                        <div class="text-md card__address">
                                            <div class="card__address-value">${data.address}</div>
                                        </div>
                                    `
                                  : ''}
                              ${data.phone && data.phoneUrl
                                  ? html`
                                        <div class="card__bottom">
                                            <a
                                                href="tel:${data.phoneUrl}"
                                                class="link"
                                                target="_blank"
                                                rel="noopener nofollow noreferrer"
                                                ><span class="btn__text">${data.phone}</span></a
                                            >
                                        </div>
                                    `
                                  : ''}
                          </div>
                      `,
                  )
                : ''}
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-yandex-map': YandexMap;
    }
}
