import gsap from 'gsap';
import { CountUp } from 'countup.js';

export const preloaderAnimationDuration = 1.5; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const counterContainer = document.querySelector<HTMLElement>('.js-preloader-counter-container');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const line = document.querySelector<HTMLElement>('.js-preloader-line');

    const state = {
        completed: false,
    };

    document.body.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));

            const tl = gsap.timeline();

            tl.to(counterContainer, { duration: 0.9, opacity: 0 })
                .to(
                    preloader,
                    {
                        duration: 0.2,
                        opacity: 0,
                        onComplete: () => {
                            preloader?.classList.add('preloader--hidden');
                            state.completed = true;
                        },
                    },
                    0.5,
                )
                .add(resolve, 0.65);
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    resolve();
                }
            }
        });
    }
    const counterInstance = counter
        ? new CountUp(counter, 0, {
              useEasing: true,
              duration: 0.5,
          })
        : null;

    function setPercent(value: number) {
        counterInstance?.update(value);

        if (line) {
            line.style.transform = `scaleX(${value / 100})`;
        }
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
        const tl = gsap.timeline();
        tl.add(() => {
            setPercent(50);
        }, 0.25).add(() => {
            setPercent(100);
        }, 0.5);
        /*
        Мама собирает сыну обед в школу:
        — Вот, положила тебе в ранец хлеб, колбасу и гвозди.
        — Мам, нафига??
        — Ну как же, берешь хлеб, кладешь на него колбасу и ешь.
        — А гвозди?
        — Так вот же они!
        */
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
